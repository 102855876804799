import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    nowRouterLayout: 'Default',
    myMenuActive: '/',
    pageSizes: [],
    menus: [], //导航菜单
    allAuthRuleModuleNameKeyList: {}, //授权列表
    showView: true, //展示view
    activePath: '', //当前path
    serverTime: 0,

  },
  mutations: {
    setNowRouterLayout(state, val) {
      state.nowRouterLayout = val;
    },
    setMyMenuActive(state, val) {
      state.myMenuActive = val;
    },
    setPageSizes(state, val) {
      state.pageSizes = val;
    },
    setMenus(state, val){
      state.menus = val;
    },
    setAllAuthRuleModuleNameKeyList(state, val){
      state.allAuthRuleModuleNameKeyList = val;
    },
    setShowView(state, val){
      state.showView = val;
    },
    setActivePath(state, val){
      state.activePath = val;
    },
    setServerTime(state, val){
      state.serverTime = val;
    }
  },
  actions: {},
  modules: {},
});
