import axios from 'axios';
import Request from 'luch-request' // 使用npm
import qs from 'qs';
import router from "../router";
import store from "../store";
import hasha from "hasha";

const http = new Request();
class myfun {
  private static axios: any;
  constructor() {
    myfun.axios = axios;
  }
  /*
   * localstorage 存储方法
   * @ param {String} 	key 键
   * @ param {String} 	value 值，
   * @ param {String} 	expired 过期时间，以秒为单位，非必须
   */
  static mySetStorage(key: string, value: string, expired: number, isExpired: boolean = false) {
    localStorage.setItem(key, JSON.stringify(value));
    if (expired) {
      if(!isExpired){
        localStorage.setItem(
          `${key}__expires__`,
          (Date.now() / 1000 + expired).toString()
        );
      }else{
        localStorage.setItem(
          `${key}__expires__`,
          expired.toString()
        );
      }
    } else {
      localStorage.removeItem(`${key}__expires__`);
    }
    return value;
  }


  /*
   * localstorage 获取方法
   * @ param {String} 	key 键
   * @ param {String} 	expired 存储时为非必须字段，所以有可能取不到，默认为 Date.now+1
   */
  static myGetStorage(key: string)  {
    const now = Date.now() / 1000;
    const expired = Number(localStorage.getItem(`${key}__expires__`)) || now + 1;

    // console.log(now);
    // console.log(expired);


    if (now >= expired) {
      localStorage.removeItem(key);
      return null;
    } else {
      // console.log('还没有过期' + now + '|' + expired)
    }

    // console.log(key+"时间" + now + "|" + expired + "|" + "相差" + (expired - now));

    const val = localStorage.getItem(key);

    try {
      return typeof(val) === "string" && val ? JSON.parse(val) : null;
    } catch (e) {
      return val;
    }

  }

  /**
   * 删除
   * @param key
   */
  static myDelStorage(key: string) {
    localStorage.removeItem(key);
    localStorage.removeItem(`${key}__expires__`);
    return true;
  }



  /**
   * 设置参数为undefined 或者 null的 时候 值改为 空字符串
   * @param params
   */
  static handleParamsEmpty = (params: any) => {
    const paramsTmp = {};
    for (const key in params){
      // @ts-ignore
      paramsTmp[key] = typeof(params[key]) === 'undefined' || params[key] === null ? '' :  typeof(params[key]) === 'object' ? JSON.stringify(params[key]) : params[key];


    }
    return paramsTmp;
  }


  /**
   * json对象排序
   */
  static  myJsonSort = (json: any) => {
    let arr = [];
    for (const key in json){
      arr.push(key);
    }
    arr = arr.sort();

    const jsonTmp = {};

    for(const v of arr) {
      // @ts-ignore
      jsonTmp[v] = json[v];
    }

    return jsonTmp;

  }





  /**
   * 获取签名
   * @param params
   */
  static getSign = (params = {}) => {
    params = myfun.myJsonSort(params);
    let signStr = '';
    for (const key in params){
      // @ts-ignore
      signStr += params[key];
    }

    const signTmp = hasha(signStr, {algorithm: 'md5'});
    let token = '';
    try{
      token = typeof(myfun.myGetStorage('userInfo').token) === 'undefined' || myfun.myGetStorage('userInfo').token === null ? '' : myfun.myGetStorage('userInfo').token;
    }catch (e) {
      token = '';
    }

    return hasha(signTmp + token, {algorithm: 'md5'});
  }


  /**
   * 请求接口公共方法
   */
  static request(route: any, params:any = {openid: '', token: ''}, reqType = 'post', isMultipart = false) {

    console.log(process.env)
    console.log(process.env.VUE_APP_API_URL2+route)
    return new Promise((resolve, reject) => {
        //执行异步ajax请求
        const url = '/mapi/'+process.env.VUE_APP_API_URL2+route;
        let promise;

        //处理公共请求参数
        if(!isMultipart){
          try{
            params.openid = myfun.myGetStorage('userInfo').openid;
            params.token = myfun.myGetStorage('userInfo').token;
          }catch (e) {
            params.openid = '';
            params.token = '';
          }

          params.platform = 'admin_h5';
          params.version = '1.0.0';
          params.lang = "zh-cn";
          params.time = parseInt((Date.now() / 1000).toString());
          params = myfun.handleParamsEmpty(params);
          params.sign = myfun.getSign(params);
          // params.mywd = '';


        }else{
          try{
            params.append('openid',myfun.myGetStorage('userInfo').openid);
            params.append('token',myfun.myGetStorage('userInfo').token);
          }catch (e) {
            params.append('openid','');
            params.append('token','');
          }

          params.append('platform','admin_h5');
          params.append('version','1.0.0');
          params.append('lang','zh-cn');
          params.append('time',parseInt((Date.now() / 1000).toString()));
          params = myfun.handleParamsEmpty(params);
          params.append('sign',myfun.getSign(params));
          params.append('mywd','');
        }




        if(!isMultipart){
          switch (reqType) {
            case 'post':
              promise = axios.post(url, qs.stringify(params));
              break;
            case 'get':
              promise = axios.get(url, {params: params});
              break;
          }
        }else{
          if(reqType != 'post'){
            alert('必须使用post方法')
            return false;
          }


          promise = this.axios.post(url, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        }


        promise.then((response: { data: any; }) => {
          //成功的回调函数
          if(response.data.code == 3 || response.data.code == 5){

            // if(process.env.VUE_APP_ENVIRONMENT != 'local' || true ){
              router.push('/user/login')
            // }
          }
          resolve(response.data)
        }).catch((error: any) => {
          if(process.env.VUE_APP_ENVIRONMENT != 'local'){
            // console.log('请求失败，去登陆');
            // setTimeout(() => {
              //可能登录失败 跳转登录地址
              // router.push("/" + encodeURIComponent(''));
              alert('系统繁忙，请稍后重试');
              //失败的回调函数
              reject(error)
            // },0)
          }


        })
      }
    )
  }



  getStrLength(str: string) {
    ///<summary>获得字符串实际长度，中文2，英文1</summary>
    ///<param name="str">要获得长度的字符串</param>
    let realLength = 0;
    const len = str.length;
    const charCode = -1;
    for (let i = 0; i < len; i++) {
      const charCode = str.charCodeAt(i);
      if (charCode >= 0 && charCode <= 128) realLength += 1;
      else realLength += 2;
    }
    return realLength;
  };

//js截取字符串，中英文都能用
//如果给定的字符串大于指定长度，截取指定长度返回，否者返回源字符串。
//字符串，长度
  /**
   * js截取字符串，中英文都能用
   * @param str：需要截取的字符串
   * @param len: 需要截取的长度
   */
  static cutstr(str: any, len: any) {
    let strLength = 0;
    let strLen = 0;
    let strCut = new String();
    strLen = str.length;
    for (let i = 0; i < strLen; i++) {
      const a = str.charAt(i);
      strLength++;
      if (escape(a).length > 4) {
        //中文字符的长度经编码之后大于4
        strLength++;
      }
      strCut = strCut.concat(a);
      if (strLength >= len) {
        strCut = strCut.concat("...");
        return strCut;
      }
    }
    //如果给定字符串小于指定长度，则返回源字符串；
    if (strLength < len) {
      return str;
    }
  }



  /**
   * 使用属jquery的inArray方法判断元素是否存在于数组中
   * @param search
   * @param array
   */
  static inArray(search: any,array: any){
    for(const i in array){
      if(array[i]==search){
        return true;
      }
    }
    return false;
  }



  /**
   * 自动路由跳转
   * @param path
   * @param params
   */
  static myRouterAutoPush = (path: any, params = {}, isReplace = 0) => {
    router.push({ path: path, query: params})
  }

  /**
   * 检测是否有对应权限
   */
  static checkAuthRule(moduleName: any){
     // @ts-ignore
    return typeof(store.state.allAuthRuleModuleNameKeyList[moduleName]) !== 'undefined';
  }



  static utc2beijing(utc_datetime: any) {
      // 转为正常的时间格式 年-月-日 时:分:秒
    console.log(typeof(utc_datetime));
    console.log(utc_datetime)
      let T_pos = utc_datetime.indexOf('T');
      let Z_pos = utc_datetime.indexOf('Z');
      let year_month_day = utc_datetime.substr(0,T_pos);
      let hour_minute_second = utc_datetime.substr(T_pos+1,Z_pos-T_pos-1);
      let new_datetime = year_month_day+" "+hour_minute_second; // 2017-03-31 08:02:06

      // 处理成为时间戳
      let timestamp: any = new Date(Date.parse(new_datetime));
      timestamp = timestamp.getTime();
      timestamp = timestamp/1000;

      // 增加8个小时，北京时间比utc时间多八个时区
      timestamp = timestamp+8*60*60;

      // 时间戳转为时间
      let beijing_datetime: any = new Date(parseInt(timestamp) * 1000).toLocaleString().replace(/年|月/g, "-").replace(/日/g, " ");
      return beijing_datetime; // 2017-03-31 16:02:06
  }

  /**
   * 获取指定时区当前时间
   * timezone 目标时区时间，东八区   东时区正数 西市区负数
   * @param timezone
   */
  static getTimeZoneTime(timezone = 8){
    let offset_GMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
    let nowDate = new Date().getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
    let targetDate: any = new Date(nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000);
    // console.log("东8区现在是：" + targetDate);
    return targetDate;
  }

  /**
   * 时间戳格式化为日期格式
   */
  static timestampToDatetime(timestamp: any){

    if(timestamp.toString().length == 10){
      timestamp = timestamp * 1000;
    }

    let timeObject = new Date(timestamp);
    let y:any = timeObject.getFullYear();
    let m:any = timeObject.getMonth()+1;
    if(m < 10){
      m = '0'+m;
    }
    let d:any = timeObject.getDate();
    if(d < 10){
      d = '0'+d;
    }
    let h:any = timeObject.getHours();
    if(h < 10){
      h = '0'+h;
    }

    let i:any = timeObject.getMinutes();
    if(i < 10){
      i = '0'+i;
    }
    let s:any =  timeObject.getSeconds();
    if(s < 10){
      s = '0'+s;
    }

    return y+'-'+m+'-'+d+' '+h+':'+i+':'+s;
  }

  /**
   * 日期格式转为时间戳格式
   */
  static datetimeToTimestamp(dateTime: any){
    let timeObject = new Date(dateTime);
    let timestamp = timeObject.getTime() / 1000;
    timestamp = parseInt(timestamp.toString());
    return timestamp;
  }


}

export default myfun;
