<template>
  <div class="home">
    这是首页
  </div>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: "Home",
    components: {
    },
    data() {
      return {
      }
    },
    methods:{
    }
  };
</script>


<style>
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }

  .el-footer {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }

  .el-aside {
    color: #333;
  }
</style>
