<template>
  <div id="app">
    <Default v-if="layout === 'Default'"></Default>
    <SinglePage v-else-if="layout === 'SinglePage'"></SinglePage>
  </div>
</template>

<script>
import Default from "@/views/layout/Default";
import SinglePage from "@/views/layout/SinglePage";
import myfun from "@/util/myfun";
export default {
  components: { SinglePage, Default },
  beforeCreate() {
    //如果已经登录则首次加载获取一次最新权限
    //判断是否登录
    const userInfo = myfun.myGetStorage("userInfo");
    if (userInfo) {
      //登录
      //验证通过执行请求
      this.myfun
          .request("adminUserGetUserLoginInfo.api", {}, "post")
          .then((result) => {
            if (result.code == "1") {
              let userInfo = this.myfun.myGetStorage("userInfo");
              userInfo.rules = result.data.rules;
              userInfo.allAuthRuleIds = result.data.allAuthRuleIds;
              userInfo.menus = result.data.menus;
              userInfo.allRuleIdKeyList = result.data.allRuleIdKeyList;
              userInfo.allRulePidKeyList = result.data.allRulePidKeyList;
              userInfo.allAuthRuleModuleNameKeyList =
                  result.data.allAuthRuleModuleNameKeyList;
              this.$store.commit(
                  "setAllAuthRuleModuleNameKeyList",
                  result.data.allAuthRuleModuleNameKeyList
              ); //设置授权列表
              this.$store.commit("setMenus", userInfo.menus);
              this.myfun.mySetStorage(
                  "userInfo",
                  userInfo,
                  userInfo.tokenExpress,
                  true
              );
              this.rules = this.myfun.myGetStorage("userInfo").rules;
              this.activeNames = this.myfun.myGetStorage(
                  "userInfo"
              ).allAuthRuleIds;
            } else {
              // this.$message({
              //   message: result.msg,
              //   type: "warning",
              // });
            }
          });
    }
  },
  created() {},
  computed: {
    layout() {
      return this.$store.state.nowRouterLayout;
    },
  },
};
</script>

<style>
@import url("/dist/css/chunk-vendors.c470e980.css");
@import url("/dist/css/app.7f08712f.css");
@import url("/dist/css/finance.e795eb97.css");
@import url("/dist/css/member.26b834f8.css");
@import url("/dist/css/order.ed23d252.css");
@import url("/dist/css/settle.d976fd77.css");
@import url("/dist/css/system.ed23d252.css");
@import url("/dist/css/user.6df1cc50.css");
html,
body {
  padding: 0;
  margin: 0;
  border: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
  "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  min-width: 75rem;
}
#app {
  /*  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  /*  overflow: hidden;*/
  /* height: 100%; */
  border-right-width: 0;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.op .el-submenu__title {
  background-color: #263445 !important;
}
.op .el-menu-item {
  background-color: #263445 !important;
}
/* switch按钮样式 */
.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}
/*打开时文字位置设置*/
.switch .el-switch__label--right {
  z-index: 1;
}
/* 调整打开时文字的显示位子 */
.switch .el-switch__label--right span {
  margin-left: 5px;
}
/*关闭时文字位置设置*/
.switch .el-switch__label--left {
  z-index: 1;
}
/* 调整关闭时文字的显示位子 */
.switch .el-switch__label--left span {
  margin-left: 20px;
}
/*显示文字*/
.switch .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 55px !important;
  margin: 0;
}
</style>
