import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import myfun from "@/util/myfun";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: 'Default'
    },
  },
  /**************会员模块路由**********************/
  {
    path: "/member/list",
    name: "MemberList",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/member/member-net-search",
    name: "MemberNetSearch",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberNetSearch.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/member/member-settings",
    name: "MemberSettings",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberSettings.vue"),
    meta: {
      layout: 'Default',
      title: '会员设置'
    },
  },




  {
    path: "/video/list",
    name: "VideoList",
    component: () =>
      import(/* webpackChunkName: "video" */ "../views/video/VideoList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/video/add",
    name: "VideoAdd",
    component: () =>
      import(/* webpackChunkName: "video" */ "../views/video/VideoAdd.vue"),
    meta: {
      layout: 'Default'
    },
  },
  /**************用户模块路由**********************/
  {
    path: "/user/login",
    name: "UserLogin",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/Login.vue"),
    meta: {
      layout: 'SinglePage',
      title: '用户登陆'
    },
  },
  {
    path: "/user/auth-rule-manage",
    name: "UserLogin",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/AuthRuleManage.vue"),
    meta: {
      layout: 'Default',
      title: '权限管理'
    },
  },
  {
    path: "/user/user-list",
    name: "UserList",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/UserList.vue"),
    meta: {
      layout: 'Default',
      title: '用户列表'
    }
  },
  {
    path: "/user/auth-user-authorize",
    name: "UserAuthorize",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/UserAuthorize.vue"),
    meta: {
      layout: 'Default',
      title: '用户授权',
      menu: '/user/user-list'
    },
  },
  {
    path: "/user/auth-role-list",
    name: "RoleList",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/RoleList.vue"),
    meta: {
      layout: 'Default',
      title: '角色列表'
    }
  },
  {
    path: "/user/auth-role-authorize",
    name: "RoleAuthorize",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/RoleAuthorize.vue"),
    meta: {
      layout: 'Default',
      title: '角色授权',
      menu: '/user/auth-role-list'
    },
  },
  /**************系统模块路由**********************/
  {
    path: "/system/modify-password",
    name: "SystemModifyPassword",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/system/ModifyPassword.vue"),
    meta: {
      layout: 'Default',
      title: '修改密码'
    },
  },
  {
    path: "/system/basic-settings",
    name: "SystemBasicSettings",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/system/BasicSettings.vue"),
    meta: {
      layout: 'Default',
      title: '基本设置'
    },
  },
  {
    path: "/system/operation-log",
    name: "SystemOperationLog",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/system/OperationLog.vue"),
    meta: {
      layout: 'Default',
      title: '操作日志'
    },
  },
  /**************订单模块路由**********************/
  {
    path: "/order/list",
    name: "OrderList",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/order/OrderList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  /**************财务模块路由**********************/
  {
    path: "/finance/apply-list",
    name: "ApplyList",
    component: () =>
      import(/* webpackChunkName: "finance" */ "../views/finance/ApplyList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/finance/account-balance-list",
    name: "AccountBalanceList",
    component: () =>
      import(/* webpackChunkName: "finance" */ "../views/finance/AccountBalanceList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/finance/account-log-list",
    name: "AccountLogList",
    component: () =>
      import(/* webpackChunkName: "finance" */ "../views/finance/AccountLogList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  /**************结算模块路由**********************/
  {
    path: "/settle/settle-list",
    name: "SettleList",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/SettleList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/settle/settle-bonus-list",
    name: "SettleBonusList",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/SettleBonusList.vue"),
    meta: {
      layout: 'Default',
      keepAlive: false // 不需要缓存
    },
  },
  {
    path: "/settle/bonus-list",
    name: "BonusList",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/BonusList.vue"),
    meta: {
      layout: 'Default',
      title: '奖金明细'
    },
  },
  {
    path: "/settle/settle-pv-list",
    name: "SettlePvList",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/SettlePvList.vue"),
    meta: {
      layout: 'Default',
      title: '业绩查询'
    },
  },
  {
    path: "/settle/settle-activity-statistics",
    name: "SettlActivityStatistics",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/SettleActivityStatisticsList.vue"),
    meta: {
      layout: 'Default',
      title: '活动查询'
    },
  },
  {
    path: "/settle/set-billing-area",
    name: "SetBillingArea",
    component: () =>
        import(/* webpackChunkName: "settle" */ "../views/settle/SetBillingArea.vue"),
    meta: {
      layout: 'Default',
      title: '结算区域设置'
    },
  },
  /**************商品模块路由**********************/
  {
    path: "/goods/list",
    name: "GoodsList",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/goods/GoodsList.vue"),
    meta: {
      layout: 'Default'
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});


//全局路由前置守卫
router.beforeEach((to, from, next) => {
  //获取当前路由对应的layout
  store.commit('setNowRouterLayout', to.meta.layout);

  //判断是否登录
  const userInfo = myfun.myGetStorage('userInfo');
  if(userInfo){ //登录

  }else{ //未登录
    const login = /^\/user\/login/g;
    const register = /^\/user\/register/g;
    if(!login.test(to.path) && !register.test(to.path)){
      if(from.path === '/user/login'){
        store.commit('setNowRouterLayout', 'SinglePage');  //防止登录页面被嵌套
      }else{
        router.replace('/user/login')
      }
      return;
    }
  }
  next();
})
// 全局路由后置守卫
router.afterEach((to, from) => {

  //设置当前所属导航
  if(to.meta.menu){
    store.commit('setMyMenuActive', to.meta.menu)
  }else{
    store.commit('setMyMenuActive', to.path)
  }

})

export default router;
