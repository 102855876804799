<template>
  <el-container ref="homePage">
    <el-aside width="250px">
      <div class="left-logo">
<!--        <img src="../../static/images/logo.png" />-->
        <div class="logo-name">长青结算管理系统</div>
      </div>
      <div class="left-name">
        <img class="left-name-img" src="../../static/images/headpic.png" />
        <div class="name-main">
          <span>{{uname}}</span>
          <span class="name-guanli">{{full_name}}</span>
        </div>
      </div>
      <MyMenu></MyMenu>
    </el-aside>

    <el-container>
      <el-main> <router-view v-if="showView" /></el-main>
    </el-container>
  </el-container>
</template>

<script>
import MyMenu from "@/components/MyMenu";
export default {
  name: "Default",
  components: {
    MyMenu,
  },
  data() {
    return {
      getServerTimeRuning: false,
      serverTimeInterVal: {},
      clientHeight: "",
      uname:"载入中...",
      full_name:"载入中..."
    };
  },
  mounted() {
    // 获取浏览器可视区域高度
    this.clientHeight = `${document.documentElement.clientHeight}`;
    //document.body.clientWidth;
    //console.log(self.clientHeight);
    window.onresize = function temp() {
      this.clientHeight = `${document.documentElement.clientHeight}`;
    };
  },
  watch: {
    // 如果 `clientHeight` 发生改变，这个函数就会运行
    clientHeight: function () {
      this.changeFixed(this.clientHeight);
    },
  },
  methods: {
    changeFixed(clientHeight) {
      //动态修改样式
      console.log(clientHeight);
      console.log(this.$refs.homePage.$el.style.height);
      this.$refs.homePage.$el.style.height = clientHeight - 0 + "px";
    },
  },
  computed: {


    showView() {
      return this.$store.state.showView;
    },
    serverTime() {
      if (this.$store.state.serverTime === 0) {
        return "同步中...";
      } else {
        return this.$store.state.serverTime;
      }
    },
  },
  created() {
    let userInfo = this.myfun.myGetStorage("userInfo");
    this.uname = userInfo.uname;
    this.full_name = userInfo.full_name;
    if (!this.$store.state.serverTime) {
      clearInterval(this.serverTimeInterVal);
      this.serverTimeInterVal = setInterval(() => {
        let date = 0;
        if (this.$store.state.serverTime !== 0) {
          let datetmp = this.$store.state.serverTime.replace(
            new RegExp(/-/gm),
            "/"
          ); //将所有的'-'转为'/'即可 兼容所有浏览器
          date = new Date(datetmp);
          this.$store.commit(
            "setServerTime",
            this.myfun.timestampToDatetime(date.getTime() + 1000)
          );
        }

        // console.log('aaa',date.getTime() % 60000, date.getTime());
        if (
          (date === 0 || date.getTime() % 60000 === 0) &&
          !this.getServerTimeRuning
        ) {
          // this.$store.commit('setServerTime', 0)
          // this.getServerTimeRuning = true;
          // setTimeout(() => {
          //   //验证通过执行请求
          //   this.myfun
          //     .request("commonPubGetServeTime.api", {}, "post")
          //     .then((result) => {
          //       if (result.code == "1") {
          //         this.$store.commit("setServerTime", result.data.serverTime);
          //       } else {
          //         this.$message({
          //           message: result.msg,
          //           type: "warning",
          //         });
          //       }
          //       this.getServerTimeRuning = false;
          //     });
          // }, 500);
        }
      }, 1000);
    }
  },
};
</script>

<style>
.el-scrollbar__wrap {
  overflow-x: hidden;
}
.name-guanli {
  font-size: 12px;
  padding-top: 5px;
}
.name-main {
  font-size: 14px;
  color: #fff;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}
.left-name-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.left-name {
  padding: 30px 23px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: url(../../static/images/cover-2-lg.png) center center;
  background-size: cover;
}
.logo-name {
  font-size: 14px;
  color: #fff;
}
.left-logo {
  padding: 16px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-footer {
  padding: 0;
  margin: 0;
}
.el-aside {
  display: block;
  position: relative;
  overflow-y: scroll;
  background: #304156;
}
</style>
